.main {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #494949;
}

.sub-main {
    display: flex;
    
}

@media screen and (max-width: 768px) {
    .sub-main {
        flex-direction: column;
        width: 95%;
        align-items: center;
    }
}