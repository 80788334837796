.player {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin: 8px 0;
  border-radius: 8px;
}

.left {
  display: flex;
  align-items: center;
  margin-left: 5px;
}

.left img {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin-right: 10px;
}

.left p {
  font-size: 18px;
  margin-left: 10px;
}

.right {
    margin-right: 10px;
    padding: 8px;
    background-color: rgb(97, 97, 97);
    border-radius: 8px;
    text-align: center;
}

.right p {
  font-size: 18px;
  line-height: 19px;
  color: whitesmoke;
  margin: 0;
}