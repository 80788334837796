.totalboard {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 5px 10px;
  background-color: #f5f5f5;
  border: 1px solid #ddd;
  border-radius: 8px;
  width: 37vw;
}

.board {
  margin-bottom: 5px;
  width: 98%;
}

@media screen and (max-width: 768px){
  .totalboard {
    width: 100%;
  }
}